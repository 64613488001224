import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-temecula-california.png'
import image0 from "../../images/cities/scale-model-of-the-barn-stage-company-in-temecula-california.png"
import image1 from "../../images/cities/scale-model-of-brainy-actz-escape-rooms-temecula-in-temecula-california.png"
import image2 from "../../images/cities/scale-model-of-morgan-hill-park-in-temecula-california.png"
import image3 from "../../images/cities/scale-model-of-pennypickle's-workshop-in-temecula-california.png"
import image4 from "../../images/cities/scale-model-of-callaway-vineyard-&-winery-in-temecula-california.png"
import image5 from "../../images/cities/scale-model-of-california-oaks-sports-park-in-temecula-california.png"
import image6 from "../../images/cities/scale-model-of-europa-village-in-temecula-california.png"
import image7 from "../../images/cities/scale-model-of-pond-park,-murrieta,-ca-in-temecula-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Temecula'
            state='California'
            image={image}
            lat='33.4936391'
            lon='-117.14836479999997'
            attractions={ [{"name": "The Barn Stage Company", "vicinity": "42051 Main St, Temecula", "types": ["point_of_interest", "establishment"], "lat": 33.4928879, "lng": -117.1463718}, {"name": "Brainy Actz Escape Rooms Temecula", "vicinity": "28481 Rancho California Rd #108, Temecula", "types": ["point_of_interest", "establishment"], "lat": 33.497538, "lng": -117.15742799999998}, {"name": "Morgan Hill Park", "vicinity": "45316 Morgan Hill Dr, Temecula", "types": ["park", "point_of_interest", "establishment"], "lat": 33.468267, "lng": -117.07093700000001}, {"name": "Pennypickle's Workshop", "vicinity": "42081 Main St, Temecula", "types": ["museum", "point_of_interest", "establishment"], "lat": 33.492924, "lng": -117.14954899999998}, {"name": "Callaway Vineyard & Winery", "vicinity": "32720 Rancho California Rd, Temecula", "types": ["food", "point_of_interest", "establishment"], "lat": 33.524426, "lng": -117.08985200000001}, {"name": "California Oaks Sports Park", "vicinity": "40600 California Oaks Rd, Murrieta", "types": ["park", "point_of_interest", "establishment"], "lat": 33.5737493, "lng": -117.20133099999998}, {"name": "Europa Village", "vicinity": "33475 La Serena Way, Temecula", "types": ["food", "point_of_interest", "establishment"], "lat": 33.523722, "lng": -117.083755}, {"name": "Pond Park, Murrieta, CA", "vicinity": "39895 Murrieta Hot Springs Rd, Murrieta", "types": ["park", "point_of_interest", "establishment"], "lat": 33.554751, "lng": -117.1650649}] }
            attractionImages={ {"The Barn Stage Company":image0,"Brainy Actz Escape Rooms Temecula":image1,"Morgan Hill Park":image2,"Pennypickle's Workshop":image3,"Callaway Vineyard & Winery":image4,"California Oaks Sports Park":image5,"Europa Village":image6,"Pond Park, Murrieta, CA":image7,} }
       />);
  }
}